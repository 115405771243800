import "./Icons.css";

export const HeartIcon = ({ w = 20, h = 20 }) => {
  return (
    <div id="heart-icon__wrapper">
      <div className="cross-sign">
        <svg width="20px" height="20px" viewBox="0 0 20 20" fill="none">
          <g filter="url(#filter0_d_501_392)">
            <path
              d="M14.9643 4.45197H11.6413V1.12461C11.6413 0.9128 11.4701 0.741333 11.2585 0.741333H8.63733C8.42579 0.741333 8.25455 0.9128 8.25455 1.12461V4.45197H4.9316C4.72007 4.45197 4.54883 4.62343 4.54883 4.83524V7.45992C4.54883 7.67173 4.72007 7.8432 4.9316 7.8432H8.25455V11.1706C8.25455 11.3824 8.42579 11.5538 8.63733 11.5538H11.2585C11.4701 11.5538 11.6413 11.3824 11.6413 11.1706V7.8432H14.9643C15.1758 7.8432 15.347 7.67173 15.347 7.45992V4.83524C15.347 4.62343 15.1758 4.45197 14.9643 4.45197Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_501_392"
              x="0.548828"
              y="0.741333"
              width="18.7982"
              height="18.8125"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_501_392"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_501_392"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="back">
        <svg
          width={w}
          height={h}
          viewBox={`0 0 ${w} ${h}`}
          fill="none"
          className="left"
        >
          <path
            d="M12.5349 14.018C12.5584 13.9944 12.5819 13.972 12.6054 13.9485C15.7919 10.7578 15.7919 5.5847 12.6054 2.39407C9.41903 -0.796559 4.25273 -0.796559 1.06632 2.39407C1.04282 2.4176 1.02043 2.44114 0.998047 2.46467V24.2656C1.805 24.2656 2.61196 23.9574 3.22753 23.341L12.5372 14.0191L12.5349 14.018Z"
            fill="#87A173"
          />
        </svg>
        <svg
          width={w}
          height={h}
          viewBox={`0 0 ${w} ${h}`}
          fill="none"
          className="right"
        >
          <path
            d="M12.5349 14.018C12.5584 13.9944 12.5819 13.972 12.6054 13.9485C15.7919 10.7578 15.7919 5.5847 12.6054 2.39407C9.41903 -0.796559 4.25273 -0.796559 1.06632 2.39407C1.04282 2.4176 1.02043 2.44114 0.998047 2.46467V24.2656C1.805 24.2656 2.61196 23.9574 3.22753 23.341L12.5372 14.0191L12.5349 14.018Z"
            fill="#C7CFA2"
          />
        </svg>
      </div>
    </div>
  );
};

export const BookIcon = ({
  w = 22,
  h = 26,
  color = "#000",
  border = "#000",
}) => {
  return (
    <svg width={w} height={h} viewBox="0 0 22 26" fill="none">
      <path
        d="M20.4664 21.0467H20.4921C20.5382 21.0467 20.5827 21.0402 20.6256 21.0309C20.6299 21.0309 20.6328 21.0319 20.6358 21.0319C20.9617 21.0319 21.225 20.7843 21.225 20.48V1.54963C21.225 1.24532 20.962 0.997803 20.6358 0.997803H3.84581C2.27754 0.997803 1.00387 2.19158 1.00387 3.65911C1.00387 3.67794 1.00782 3.69708 1.00947 3.71714C1.00815 5.07171 0.9897 20.8732 1.00815 22.5105C1.00717 22.5426 1.00255 22.5735 1.00255 22.6059C1.00255 24.0169 2.18131 25.1718 3.66621 25.2595C3.69488 25.2635 3.7252 25.2678 3.75519 25.2678H20.588C20.8793 25.2678 21.1153 25.0465 21.1153 24.774V24.6589C21.1153 24.386 20.879 24.1651 20.588 24.1651H20.4644C20.0439 23.9814 19.722 23.3861 19.7009 22.6803L19.7015 22.6719C19.7029 22.6577 19.7039 22.6435 19.7039 22.6287C19.7039 22.609 19.7032 22.5812 19.7025 22.5454L19.7015 22.5031C19.7302 21.8121 20.0522 21.2285 20.4657 21.047L20.4664 21.0467ZM19.4165 22.4963V22.5L19.4172 22.55C19.4178 22.5837 19.4185 22.6099 19.4185 22.6284C19.4185 22.6358 19.4178 22.6426 19.4172 22.6497L19.4152 22.6744V22.6803C19.4323 23.3015 19.6561 23.8401 19.9906 24.1648H3.84416C2.95045 24.1648 2.21822 23.4984 2.18263 22.6688C2.18395 22.6543 2.18691 22.6404 2.18691 22.6256C2.18691 22.6016 2.18559 22.5639 2.18395 22.5127C2.23535 21.697 2.96001 21.0464 3.84416 21.0464H19.9932C19.6643 21.3655 19.4405 21.8908 19.4162 22.496L19.4165 22.4963Z"
        fill={color}
        stroke={border}
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AccountIconDefault = ({ w = 38, h = 38, color = "#000" }) => {
  return (
    <svg width={w} height={h} viewBox="0 0 37 38" fill="none">
      <g clipPath="url(#clip0_355_2349)">
        <path
          d="M24.8743 36.4052C34.4165 32.951 39.3518 22.4152 35.8976 12.873C32.4433 3.33071 21.9076 -1.6046 12.3653 1.84965C2.82306 5.3039 -2.11225 15.8397 1.342 25.3819C4.79625 34.9242 15.332 39.8595 24.8743 36.4052Z"
          fill={color}
        />
        <path
          d="M22.8886 11.7647C22.8886 14.117 20.9763 16.0294 18.6239 16.0294C16.2716 16.0294 14.3593 14.117 14.3593 11.7647C14.3593 9.41234 16.2716 7.5 18.6239 7.5C20.9763 7.5 22.8886 9.41234 22.8886 11.7647Z"
          fill="white"
        />
        <path
          d="M18.624 17.4933C14.2577 17.4933 10.7123 21.0387 10.7123 25.405C10.7123 26.5473 11.6431 27.4781 12.7854 27.4781H24.454C25.5964 27.4781 26.5272 26.5473 26.5272 25.405C26.5272 21.0387 22.9817 17.4933 18.6155 17.4933H18.624Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_355_2349">
          <rect
            width="36.75"
            height="36.75"
            fill="white"
            transform="translate(0.25 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CaretDownIcon = ({ w = 22, h = 13, color = "#000" }) => {
  return (
    <svg width={w} height={h} viewBox="0 0 22 13" fill="none">
      <path
        d="M20.3 0.5H1.69513C0.186633 0.5 -0.567618 2.32139 0.500904 3.38911L8.64052 11.5226C9.94475 12.8258 12.0661 12.8258 13.3703 11.5226L16.4659 8.42934L21.5099 3.38911C22.5627 2.32139 21.8085 0.5 20.3 0.5Z"
        fill={color}
      />
    </svg>
  );
};
